import React from "react";

function ExperienceContainer() {
  return (
    <div className="experience-container">
      <div className="experience">
        <span className="title">5+</span>
        <span className="desc">Years Experience</span>
      </div>
      <div className="experience">
        <span className="title">20+</span>
        <span className="desc">Projects</span>
      </div>
      <div className="experience">
        <span className="title">1</span>
        <span className="desc">Hackathon Won</span>
      </div>
      <div className="experience">
        <span className="title">7</span>
        <span className="desc">Projects Led</span>
      </div>
    </div>
  );
}

export default ExperienceContainer;
