export const absoluteApiDomain = "https://darpankattel.pythonanywhere.com/api/"; // only absolute direct link, not proxy
export const apiDomain = absoluteApiDomain; // can be used with proxy
export const absoluteApiDomainOnly = "https://darpankattel.pythonanywhere.com/";

export const facebookLink = "https://www.facebook.com/darpan.kattel";
export const instagramLink = "https://www.instagram.com/katteldarpan/";
export const youtubeLink =
  "https://www.youtube.com/channel/UCxl0xFX2AYK2ulmU-PO_3bA";
export const linkedinLink = "https://www.linkedin.com/in/darpankattel/";
export const forthLink = "https://github.com/darpankattel/";
